import { client } from ".";
import DataTransformer from "../../helpers/dataTransformer";
import CATALOGUE from "./queries/catalogue.query";
import BIZ from "./queries/biz.query";
import STORE from "./queries/store.query";
import Item from "../../store/models/Item";
import analyticsPubSub, { ANALYTICS_EVENTS } from "../analyticsPubSub";
import { gql } from "@apollo/client";

function getCatalogue(sort_by, filter_by, fulfilment_mode) {
  return client
    .getInstance()
    .query({
      query: CATALOGUE.GET_CATALOGUE,
      variables: {
        sort_by,
        filter_by,
        fulfilment_mode,
      },
    })
    .then((res) => {
      return DataTransformer.getCatalogue(res.data, sort_by, filter_by);
    })
    .catch((error) => {
      throw error;
    });
}

function getItemOptions(item_id) {
  return client
    .getInstance()
    .query({
      query: CATALOGUE.GET_ITEM_OPTIONS,
      variables: {
        item_id: item_id,
      },
    })
    .then((res) => {
      return res.data.getItemOptionGroups;
    })
    .catch((error) => {
      throw error;
    });
}

function getQrModeBizInfo() {
  return client
    .getInstance()
    .query({
      query: BIZ.GET_BIZ_INFO,
    })
    .then((res) => {
      return res.data.getBusinessInfo;
    })
    .catch((error) => {
      throw error;
    });
}

function searchItems(keyword, fulfilment_mode, sort_by, filter_by) {
  return client
    .getInstance()
    .query({
      query: CATALOGUE.GET_ALL_ITEMS,
      variables: {
        keyword,
        fulfilment_mode,
        sort_by,
        filter_by,
      },
    })
    .then((res) => {
      return DataTransformer.getSearchResults(res.data.getAllItems);
    })
    .catch((error) => {
      throw error;
    });
}

function getItemById(item_id) {
  return client
    .getInstance()
    .query({
      query: CATALOGUE.GET_ITEM_BY_ID,
      variables: {
        item_id: item_id,
      },
    })
    .then((res) => {
      const item = new Item(res.data.getItemById);
      const eventObj = {
        item_id: item.id,
        item_title: item.name,
        item_price: item.price,
        item_category: item.category ? item.category.name : null,
        item_thumbnail: item.thumbnail,
      };
      analyticsPubSub.publish(ANALYTICS_EVENTS.PRODUCT_CLICKED, eventObj);

      return item;
    })
    .catch((error) => {
      throw error;
    });
}

function getCategoryById(category_id, sort_by, filter_by, fulfilment_mode) {
  return client
    .getInstance()
    .query({
      query: CATALOGUE.GET_CATEGORY_BY_ID,
      variables: {
        category_id,
        sort_by,
        filter_by,
        fulfilment_mode,
      },
    })
    .then((res) => {
      const items = res.data.getCategory.items.map((item) => new Item(item));
      // if (sort_by) {
      //   return DataTransformer.sortItems(items);
      // }
      return items;
    })
    .catch((error) => {
      throw error;
    });
}

function getRecommendedItems(item_ids, fulfilment_mode, raw = false) {
  return client
    .getInstance()
    .query({
      query: CATALOGUE.GET_RECOMMENDED_ITEMS,
      variables: {
        item_ids,
        fulfilment_mode,
      },
    })
    .then((res) => {
      /**
       * Added this flag temporarily to get the raw data from the API without any modelling/transformation. We will be moving the data modelling section to backend soon.
       */
      if (raw) {
        return DataTransformer.sortItems(res.data.getRecommendedItems?.objects);
      }
      const data = res.data.getRecommendedItems?.objects.map(
        (item) => new Item(item),
      );
      return DataTransformer.sortItems(data);
    })
    .catch((error) => {
      console.log(error, "error");
    });
}

function updateCart(cart) {
  return client
    .getInstance()
    .mutate({
      mutation: gql`
        mutation UserUpdateCart($cart: UpdateCart!) {
          updateCart(updateCart: $cart) {
            items
          }
        }
      `,
      variables: {
        cart: {
          items: JSON.stringify(cart.items),
          channel: cart.channel,
          fulfillment_mode: cart.fulfillment_mode,
          location_name: cart.location_name,
          city: cart.city,
        },
      },
    })
    .then((res) => {
      return res.data.updateCart;
    })
    .catch((error) => {
      throw error;
    });
}

function clearCart() {
  return client
    .getInstance()
    .mutate({
      mutation: gql`
        mutation UserClearCart {
          clearCart {
            items
          }
        }
      `,
    })
    .then((res) => {
      return res.data.updateCart;
    })
    .catch(() => {});
}

function getQsrStore() {
  return client
    .getInstance()
    .query({
      query: STORE.GET_QSR_STORE,
    })
    .then((res) => {
      return res.data.getCurrentStore;
    })
    .catch((error) => {
      throw error;
    });
}

function getLatestAnnouncement() {
  return client
    .getInstance()
    .query({
      query: STORE.GET_LATEST_ANNOUNCEMENT,
    })
    .then((res) => {
      return res.data.getLatestAnnouncement;
    })
    .catch((error) => {
      throw error;
    });
}

export default {
  getCatalogue,
  getItemOptions,
  getQrModeBizInfo,
  searchItems,
  getItemById,
  getCategoryById,
  getRecommendedItems,
  getLatestAnnouncement,

  updateCart,
  clearCart,

  getQsrStore,
};
