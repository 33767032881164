import { gql } from "@apollo/client";

export const ITEM_FRAGMENT = gql`
  fragment ItemFragment on Item {
    category {
      id
      name
    }
    current_stock
    display_price
    food_type
    has_options
    id
    images
    image_blur_hash
    image_url
    item_desc
    item_price
    item_title
    likes
    markup_price
    price_descriptor
    service_tax_rate
    slug
    sort_order
    vat_rate
    extras {
      id
      name
      key_values {
        id
        key
        value
      }
    }
  }
`;

export const CATEGORY_FRAGMENT = gql`
  fragment CategoryFragment on Category {
    id
    name
    description
    item_count
    slug
    image
  }
`;

export const SUB_CATEGORY_FRAGMENT = gql`
  fragment SubCategoryFragment on Category {
    id
    name
    description
    item_count
    slug
    image
  }
`;

export const OPTIONS_GROUP_FRAGMENT = gql`
  fragment OptionGroupFragment on ItemOptionGroup {
    id
    title
    description
    default
    max_selectable
    min_selectable
    sort_order
  }
`;

export const OPTIONS_FRAGMENT = gql`
  fragment OptionFragment on ItemOption {
    id
    title
    food_type
    price
    sort_order
    current_stock
    available
    option_group_id
  }
`;
